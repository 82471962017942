/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap");

body {
  background-color: black;
  font-family: "Manrope", sans-serif;
}

.btn {
  background-color: #e9a6a6;
}

.comps {
  background-color: #323232;
}
/*Layout Matcher*/
.main-area {
  display: grid;
  grid-template-columns: 0.8fr 1.4fr 1.6fr;
  grid-template-rows: 1fr;
  gap: 1% 1%;
  grid-template-areas: "settings movie-area ranking";
}

.movie-area {
  grid-area: movie-area;
}
.ranking {
  grid-area: ranking;
}
.navi {
  display: none;
  grid-area: navi;
}

.settings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "likedGenres likedGenres likedGenres"
    "dislikedGenres dislikedGenres dislikedGenres"
    "services services services";
  grid-area: settings;
}
.likedGenres {
  grid-area: likedGenres;
}
.dislikedGenres {
  grid-area: dislikedGenres;
}
.services {
  grid-area: services;
}

/*Scrollbar*/
.scoreboard::-webkit-scrollbar,
.genre-scroll::-webkit-scrollbar {
  width: 10px;
}

.scoreboard::-webkit-scrollbar-track,
.genre-scroll::-webkit-scrollbar {
  background: transparent;
}

.scoreboard::-webkit-scrollbar-thumb,
.genre-scroll::-webkit-scrollbar {
  background: transparent;
}

.mobileOnly {
  display: grid;
}

.margin-top {
  margin-top: 7rem;
}

@media only screen and (max-device-width: 1050px) and (orientation: portrait) and (max-aspect-ratio: 1/1) {
  .main-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.9fr 0.1fr;
    gap: 0% 0%;
    grid-template-areas:
      "movie-area"
      "navi";
  }
  .mobileOnly {
    display: none;
  }
  .margin-top {
    margin-top: auto;
    margin-bottom: auto;
  }
  .navi {
    display: grid;
  }
}
